import React from 'react'
import {Modal, ModalProps} from 'antd'
import {Color} from '../../style/colors'

/**
 * Component used for rendering a custom modal.
 * The modal will have an parametrized title and width.
 * Basically will be used in all components in edit/create mode.
 */
const CustomModal: React.FC<CustomModalProps & ModalProps> = props => {
    return (
        <Modal
            footer={null}
            style={{width: props.width}}
            closable={false}
            maskClosable={false}
            destroyOnClose={true}
            maskStyle={{background: Color.active, opacity: 0.7}}
            {...props}>
            {props.children}
        </Modal>
    )
}

type CustomModalProps = {
    title: string
    width?: number | string
}

export default CustomModal
