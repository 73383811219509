import React, {useEffect, useState} from 'react'
import {CustomModal, CustomSelect, DateInput, Input, TitleText} from '../../common'
import {OptionItem, TherapyGeneralInfo} from '../../../utils/types'
import {ButtonStyle} from '../../../style/button'
import {i18n} from '../../../i18n'
import {Button} from 'antd'
import {sharedStyles} from '../../../style/shared_styles'
import {TherapyForm, TherapyIntention} from '../../../utils/enums'
import {buildEnumOptions, buildSelectDoctors, buildSelectOptions, fromJson} from '../../../utils/helper'
import {TherapyKeys, therapyMandatoryFields} from './utils/keys'
import {observer} from 'mobx-react'
import {useForm, useLoading} from '../../../utils/hooks'
import {useStores} from '../../../store'
import {MAX_HEIGHT} from '../../../utils/constants'
import {format, isBefore} from 'date-fns'
import {Color} from '../../../style/colors'

/**
 * Component used to edit/create a therapy in a modal.
 */
const EditTherapy: React.FC<EditTherapyProps> = observer((props: EditTherapyProps) => {
    const [formState, extractProps, onValidateInputs, inputChangeHandler] = useForm(props.therapyInfo, therapyMandatoryFields)
    const [editLoading, setEditIsLoading] = useLoading(false)

    const {therapyStore, patientStore, userStore, doctorStore} = useStores()
    const [previousTherapy] = useState<TherapyGeneralInfo | undefined>(therapyStore.therapyComplete?.generalInfo)
    const [warningMessage, setWarningMessage] = useState<string>('')

    const isCreate = !props.therapyInfo

    const therapyIntentionOptions: OptionItem[] = buildEnumOptions(TherapyIntention, 'therapy.intention')
    const therapyFormOptions: OptionItem[] = buildEnumOptions(TherapyForm, 'therapy.form')
    const therapyNameOptions: OptionItem[] = buildSelectOptions(userStore.getTherapyNameList)
    const therapyDoctorOptions: OptionItem[] = buildSelectDoctors(doctorStore.getDoctors)

    const onSubmit = async (copyTherapy: boolean) => {
        const isValid = onValidateInputs()
        if (!isValid) {
            return
        }

        let data = {
            ...(formState.values as TherapyGeneralInfo),
            startDateString: formState.values.startDate ? format(formState.values.startDate, 'yyyy-MM-dd') : '',
            dateOfEntryString: formState.values.dateOfEntry ? format(formState.values.dateOfEntry, 'yyyy-MM-dd') : '',
            patientId: patientStore.patientOverview?.patient.id || ''
        }

        // update consent value only on create
        if (isCreate) {
            const areTherapyNamesEquals = previousTherapy?.therapyName === formState.values.therapyName
            data = {...data, consentGiven: areTherapyNamesEquals && !!previousTherapy?.consentGiven}
        }

        setEditIsLoading(true)
        const isSuccess = await therapyStore.createUpdateTherapy(data, copyTherapy ? previousTherapy?.id : undefined)
        setEditIsLoading(false)

        if (isSuccess) {
            props.onCloseModal?.()
        }
    }

    useEffect(() => {
        const getDoctors = async () => {
            await doctorStore.getTherapyDoctorList()
        }
        getDoctors()
    }, [])

    // prefill select options with values from previous therapy or with default one
    useEffect(() => {
        inputChangeHandler(previousTherapy?.intention || null, TherapyKeys.intention)
        inputChangeHandler(previousTherapy?.therapyForm || null, TherapyKeys.therapyForm)
        inputChangeHandler(previousTherapy?.therapyName || null, TherapyKeys.therapyName)
        inputChangeHandler(previousTherapy?.height || null, TherapyKeys.height)
        inputChangeHandler(previousTherapy?.weight || null, TherapyKeys.weight)
        inputChangeHandler(previousTherapy?.bodySurface || null, TherapyKeys.bodySurface)
        inputChangeHandler(previousTherapy?.cycleDuration || null, TherapyKeys.cycleDuration)
        inputChangeHandler(previousTherapy?.cycleNumber || null, TherapyKeys.cycleNumber)
        inputChangeHandler(previousTherapy?.doctor || null, TherapyKeys.doctor)
    }, [])

    const checkWarningMessage = () => {
        if (formState.values.startDate && isBefore(formState.values.startDate, new Date())) {
            setWarningMessage(i18n.t('common.warnings.pastDate'))
        }
    }

    return (
        <div>
            <div style={{display: 'flex'}}>
                <div style={sharedStyles.leftColumn}>
                    <CustomSelect
                        label={i18n.t('therapyTab.basicSection.intention')}
                        items={therapyIntentionOptions}
                        {...extractProps(TherapyKeys.intention)}
                    />

                    <DateInput
                        label={i18n.t('therapyTab.basicSection.startDate')}
                        {...extractProps(TherapyKeys.startDate)}
                        onBlur={checkWarningMessage}
                    />

                    <Input
                        label={i18n.t('therapyTab.basicSection.cycleNumber')}
                        min={1}
                        isInteger
                        type={'number'}
                        {...extractProps(TherapyKeys.cycleNumber)}
                    />
                </div>
                <div style={sharedStyles.rightColumn}>
                    <CustomSelect
                        label={i18n.t('therapyTab.basicSection.name')}
                        items={therapyNameOptions}
                        enableSearch={true}
                        {...extractProps(TherapyKeys.therapyName)}
                    />
                    <CustomSelect
                        label={i18n.t('therapyTab.basicSection.form')}
                        items={therapyFormOptions}
                        {...extractProps(TherapyKeys.therapyForm)}
                    />
                    <Input
                        label={i18n.t('therapyTab.basicSection.cycle')}
                        min={1}
                        isInteger
                        type={'number'}
                        {...extractProps(TherapyKeys.cycleDuration)}
                    />
                    {!isCreate && (
                        <>
                            <Input
                                label={i18n.t('therapyTab.basicSection.useOriginalDose')}
                                type={'checkbox'}
                                hideErrorField={true}
                                {...extractProps(TherapyKeys.useOriginalDose)}
                            />
                            <Input
                                label={i18n.t('therapyTab.basicSection.consentGiven.title')}
                                type={'checkbox'}
                                hideErrorField={true}
                                {...extractProps(TherapyKeys.consentGiven)}
                                extraInfo={i18n.t('therapyTab.basicSection.consentGiven.extra')}
                            />
                        </>
                    )}
                </div>
            </div>
            <CustomSelect
                label={i18n.t('therapyTab.basicSection.doctor.label')}
                enableSearch={true}
                items={therapyDoctorOptions}
                {...extractProps(TherapyKeys.doctor)}
                onValueChanged={(val, err) => inputChangeHandler(val ? fromJson(val) : val, TherapyKeys.doctor, err)}
                value={formState.values[TherapyKeys.doctor]?.name}
            />
            <Input label={i18n.t('therapyTab.basicSection.comment')} type={'text'} {...extractProps(TherapyKeys.comment)} />

            <div>
                <TitleText text={i18n.t('therapyTab.basicSection.edit.patientSection')} />
                <div style={{display: 'flex'}}>
                    <div style={sharedStyles.leftColumn}>
                        <Input
                            label={i18n.t('therapyTab.basicSection.weight')}
                            min={0}
                            max={userStore.getMaxWeight}
                            type={'number'}
                            {...extractProps(TherapyKeys.weight)}
                        />
                        <Input
                            label={i18n.t('therapyTab.basicSection.height')}
                            max={MAX_HEIGHT}
                            min={0}
                            type={'number'}
                            isInteger
                            {...extractProps(TherapyKeys.height)}
                            pixelAlignTop={true}
                        />
                    </div>
                    <div style={sharedStyles.rightColumn}>
                        <Input label={i18n.t('therapyTab.basicSection.surface')} min={0} type={'number'} {...extractProps(TherapyKeys.bodySurface)} />
                        <DateInput label={i18n.t('therapyTab.basicSection.dateEntry')} max={new Date()} {...extractProps(TherapyKeys.dateOfEntry)} />
                    </div>
                </div>
            </div>

            <div style={{display: 'flex'}}>
                <div style={sharedStyles.actionBtnLeft}>
                    <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={props.onCloseModal}>
                        {i18n.t('button.cancel')}
                    </Button>
                </div>

                <div style={sharedStyles.actionBtnRight}>
                    {isCreate && therapyStore.hasTherapyMedication && (
                        <Button
                            type={'default'}
                            shape={'round'}
                            loading={editLoading}
                            style={ButtonStyle.primary}
                            onClick={async () => onSubmit(true)}>
                            {i18n.t('therapyTab.actions.clone')}
                        </Button>
                    )}

                    <Button type={'primary'} shape={'round'} loading={editLoading} style={ButtonStyle.save} onClick={async () => onSubmit(false)}>
                        {i18n.t('button.save')}
                    </Button>
                </div>
            </div>
            <CustomModal open={!!warningMessage} title={i18n.t('notification.warning.title')}>
                <div style={{...sharedStyles.column, alignItems: 'center'}}>
                    <p key={'warningMessage'} style={{color: Color.warningYellow, fontSize: 22}}>
                        {warningMessage}
                    </p>
                    <Button type={'default'} shape={'round'} style={{...ButtonStyle.primary, marginTop: 25}} onClick={() => setWarningMessage('')}>
                        {i18n.t('button.close')}
                    </Button>
                </div>
            </CustomModal>
        </div>
    )
})

type EditTherapyProps = {
    therapyInfo?: TherapyGeneralInfo
    onCloseModal?: () => void
}

export default EditTherapy
