import {Color} from '../../../style/colors'
import {LocalStyleSheet} from '../../../utils/types'

export const styles: LocalStyleSheet = {
    box: {
        width: `${100 / 7}%`,
        height: 28,
        borderStyle: 'solid',
        borderColor: Color.white,
        borderWidth: 0.75,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    boxWrapper: {
        display: 'flex',
        flex: 1,
        backgroundColor: Color.white,
        justifyContent: 'space-between'
    },
    boxText: {
        userSelect: 'none',
        WebkitUserSelect: 'none'
    },
    excludedBox: {
        borderColor: 'lightgrey'
    }
}
