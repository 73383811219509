import {LocalStyleSheet} from "../../../utils/types";
import {Color} from "../../../style/colors";
import {sharedStyles} from "../../../style/shared_styles";
import {FontWeight} from "../../../style/text";

export const styles: LocalStyleSheet = {
    diagramRow: {
        ...sharedStyles.inlineContainer,
        marginTop: 2
    },
    dosageWrapper: {
        flex: 1.4,
        display: 'flex',
        backgroundColor: Color.neutral5,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '3%'
    },
    dosageText: {
        fontSize: 12,
        fontWeight: FontWeight.bold,
    },
    timeDoseWrapper: {
        ...sharedStyles.inlineContainer,
        justifyContent: 'space-between',
    },
    processedWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    processedEntry: {
        ...sharedStyles.inlineContainer,
        marginTop: '1.5%',
        marginBottom: '1.5%'
    }
}
