import React from 'react'
import {Color} from '../../style/colors'
import {Modal, ModalProps, Spin} from 'antd'
import {LocalStyleSheet} from '../../utils/types'

/**
 * The custom component displayed during the loading of the custom page.
 */
const FullLoadingOverlay: React.FC<ModalProps> = props => {
    return (
        <Modal
            footer={null}
            transitionName={'none'}
            maskTransitionName={'name'}
            bodyStyle={styles.bodyStyle}
            maskClosable={false}
            destroyOnClose={true}
            centered={true}
            closable={false}
            {...props}>
            <Spin size={'large'} />
        </Modal>
    )
}

const styles: LocalStyleSheet = {
    bodyStyle: {
        background: Color.active,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default FullLoadingOverlay
