const Color = {
    base1: '#753055',
    base2: '#A52E6E',
    base3: '#C46363',
    base4: '#F29663',
    base5: '#FECC6B',

    secondary1: '#6B959F',
    secondary2: '#94BFC5',
    secondary3: '#D5E8EA',

    active: '#6B42A2',
    activeDark: '#513A66',
    neutral1: '#36312B',
    neutral2: '#6A655F',
    neutral3: '#B5AFA5',
    neutral4: '#DDD8D0',
    neutral5: '#F0EDE7',

    white: '#ffffff',
    black: '#000000',
    green: '#42B57D',
    yellow: '#fce900',
    transparent: 'transparent',
    warning: '#F76A6A',
    warningStrong: '#FA3E3E',
    warningYellow: '#EED202',

    lightPurple: '#F5EEF8'
}

module.exports = {
    Color
}
