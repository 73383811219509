import React, {useEffect, useMemo, useState} from 'react'
import {CloseOutlined} from '@ant-design/icons'
import {Input, LiveSearch} from '../../../common'
import {i18n} from '../../../../i18n'
import {sharedStyles} from '../../../../style/shared_styles'
import {Button} from 'antd'
import {ButtonStyle} from '../../../../style/button'
import EmptyDiv from '../../../common/EmptyDiv'
import {ConfigTypes, SearchTypes} from '../../../../utils/enums'
import styles, {elemMargin} from '../../styles/TimeDoseTable.style'
import {IngredientSearchEntry, MedicationSearchEntry, TherapyMedicationDto} from '../../../../utils/types'
import {Color} from '../../../../style/colors'
import {Format} from '../../../../utils/constants'
import {
    checkAllowedMultiplier,
    getTimeDoseArrays,
    getTimeDoseObj,
    hasAnyValidMedicationDto,
    prepareDosageForDisplay
} from '../../../../utils/medication_helper'
import {
    convertFloatNumber,
    generateNumberArray,
    isNumber,
    matchRegex,
    TIME_REGEX,
    toLocaleStringCustom
} from '../../../../utils/helper'
import {addElement} from '../../../../utils/array_helper'
import {toNumber} from '../../../../utils/observation_helper'
import {useStores} from '../../../../store'
import {observer} from 'mobx-react'

const initialTimes = ['08:00', '12:00', '18:00']

/**
 * Component for setting the dosage and time of each med from the therapy medication.
 */
const TimeDoseTable: React.FC<TimeDoseTableProps> = observer(props => {
    const [times, setTimes] = useState<string[]>([])
    const [meds, setMeds] = useState<(MedicationSearchEntry | string)[]>([''])
    const [doses, setDoses] = useState<number[][]>([[]])
    const [totalDosage, setTotalDosage] = useState(0)

    const [dosesErr, setDosesErr] = useState<string[][]>([[]])

    const {userStore} = useStores()

    // Initialize prefilled data
    useEffect(() => {
        if (props.therapyMedicationList) {
            const {timeArr, medArr, doseArr, doseErr} = getTimeDoseArrays(props.therapyMedicationList)
            setTimes(timeArr)
            setMeds(medArr)
            setDoses(doseArr)
            setDosesErr(doseErr)
        } else {
            setTimes(initialTimes)
        }
    }, [])

    // Calculates total dosage
    useEffect(() => {
        let total = 0
        meds.forEach((med, medIndex) => {
            const medDosage = typeof med === 'string' ? toNumber(med) || 0 : (med.originallyHadDosage ? med.dosage : toNumber(med.uiDosage)) || 0
            times.forEach((time, timeIndex) => {
                const dosage = toNumber(doses[medIndex][timeIndex]?.toString()) || 0
                total += dosage * medDosage
            })
        })
        setTotalDosage(total)
    }, [meds, doses])

    const onTimeValueChange = (index: number) => (val: string) => {
        const newTimes = [...times]
        newTimes.splice(index, 1, val)
        setTimes(newTimes)
    }

    const deleteIntakeTime = (idx: number) => {
        // delete the column
        const newTimes = [...times]
        newTimes.splice(idx, 1)
        setTimes(newTimes)
        // remove the column dosage values
        const newDoses = [...doses]
        const newDosesErr = [...dosesErr]
        meds.forEach((med, medIndex) => {
            newDoses[medIndex].splice(idx, 1)
            newDosesErr[medIndex].splice(idx, 1)
        })
        setDoses(newDoses)
        setDosesErr(newDosesErr)
    }

    const addIntakeTime = () => {
        // adds the column
        const newTimes = [...times]
        newTimes.push('')
        setTimes(newTimes)
        // since the line exists dose[line][col] will not crash, no need to add anything to doses
    }

    const deleteMedicine = (idx: number) => {
        // delete the line
        const newMeds = [...meds]
        const deleted = newMeds.splice(idx, 1)
        setMeds(newMeds)
        // remove the line dosage values
        const newDoses = [...doses].filter((arr, index) => index !== idx)
        setDoses(newDoses)
        const newDoseErr = [...dosesErr].filter((arr, index) => index !== idx)
        setDosesErr(newDoseErr)
        // delete disabled data in parent component if this was the last medication
        deleteDataIfLast(deleted[0], newMeds)
    }

    const addMedicine = () => {
        // adds the line
        const newMeds = [...meds]
        newMeds.push('')
        setMeds(newMeds)
        // since the line did not previously exist, any attempt to add to doses[newLine][x] would crash without init to []
        const newDoses = [...doses]
        newDoses.push([])
        setDoses(newDoses)

        const newDoseErr = [...dosesErr]
        newDoseErr.push([])
        setDosesErr(newDoseErr)
    }
    // could be string when med is deleted: val = ""
    const onMedicineChange = (index: number) => (val: MedicationSearchEntry | string) => {
        if (typeof val !== 'string') {
            props.setMedicationChanged?.(val)
        }
        updateMedsList(index, val)
    }

    /**
     * change medication dosage for anonymous medication
     * (does not have an medicationDTO, only ingredient is selected)
     * @param index
     */
    const onMedicationDosageChange = (index: number) => (newDosage: string) => {
        const currentMedication = meds?.[index]

        // if current medication does not have dosage
        if (currentMedication && typeof currentMedication !== 'string') {
            currentMedication.uiDosage = newDosage
        }
        const replacedMedOrDosage = typeof currentMedication !== 'string' ? currentMedication : newDosage?.toString()

        updateMedsList(index, replacedMedOrDosage)
    }

    /**
     * convert current value dosage to a valid float number (if not valid, keep the value and show error on submit)
     * @param index
     */
    const onMedicationDosageBlur = (index: number) => (newDosage: string) => {
        const currentMedication = meds?.[index]

        const convertedDosage: number | undefined = convertFloatNumber(newDosage)
        // if dosage is missing from med
        if (typeof currentMedication !== 'string') {
            currentMedication.dosage = convertedDosage || null
            currentMedication.uiDosage = toLocaleStringCustom(convertedDosage, i18n.locale) || newDosage
            props.setMedicationChanged?.(currentMedication)
            updateMedsList(index, currentMedication)
        } else {
            updateMedsList(index, toLocaleStringCustom(convertedDosage, i18n.locale) || newDosage)
        }
    }

    const updateMedsList = (index: number, val: MedicationSearchEntry | string) => {
        const newMeds = [...meds]
        const deleted = newMeds.splice(index, 1, val)
        setMeds(newMeds)
        deleteDataIfLast(deleted[0], newMeds)
    }

    // if meds changes, set the unit of the last medication from list
    useEffect(() => {
        const newMeds = [...meds].filter(med => typeof med !== 'string');
        const medsLength = newMeds.length
        if (medsLength > 0) {
            const firstMedication = newMeds[0] as MedicationSearchEntry;
            props.onChangeUnit?.(firstMedication.originallyHadUnit ? (firstMedication.unit || null) : null )
        } else {
            props.onChangeUnit?.(null)
        }

    }, [meds])

    const deleteDataIfLast = (deleted: MedicationSearchEntry | string, medications: (MedicationSearchEntry | string)[]) => {
        const isTheLastSelectedMedication = !medications.some(x => typeof x !== 'string')
        const isEmptyList = medications.length === 0
        if (isEmptyList || (typeof deleted !== 'string' && isTheLastSelectedMedication)) {
            props.setMedicationChanged?.(null)
        }
    }

    const onDosageChange = (medIndex: number, timeIndex: number) => (value: number) => {
        const newDoses = [...doses]
        newDoses[medIndex][timeIndex] = value
        setDoses(newDoses)
    }

    const onDosageBlur = (medIndex: number, timeIndex: number) => {
        const toValidate = doses[medIndex][timeIndex]
        const isValid = checkAllowedMultiplier(toValidate)
        const newDoseErr = [...dosesErr]
        newDoseErr[medIndex][timeIndex] = isValid ? '' : 'errored'
        setDosesErr(newDoseErr)
    }

    useEffect(() => {
        if (props.triggerValidate) {
            onValidate()
        }
    }, [props.triggerValidate])

    const onValidate = () => {
        let errMessages: string[] = []
        const timeErrorMessage = i18n.t('editMed.error.time')
        const medErrorMessage = i18n.t('editMed.error.medication')
        const emptyRowColumnErrorMessage = i18n.t('editMed.error.emptyRowColumn')
        const doseFormatErrorMessage = i18n.t('editMed.error.multiplierFormat')

        times.forEach((time, timeIndex) => {
            if (!matchRegex(time, TIME_REGEX)) {
                errMessages = addElement(errMessages, timeErrorMessage)
            }
            // check if have values per column
            let hasOneValid = false
            doses.forEach(intakeList => {
                if (toNumber(intakeList[timeIndex]?.toString())) {
                    hasOneValid = true
                }
            })
            if (!hasOneValid) {
                errMessages = addElement(errMessages, emptyRowColumnErrorMessage)
            }
        })

        // check if have empty row
        meds.forEach((med, medIndex) => {
            const dosage = typeof med === 'string' ? med : med.dosage
            if (!convertFloatNumber(dosage) || dosage! <= 0) {
                errMessages = addElement(errMessages, medErrorMessage)
            }
            if (!doses[medIndex] || doses[medIndex].map(it => toNumber(it?.toString())).filter(it => isNumber(it)).length === 0) {
                errMessages = addElement(errMessages, emptyRowColumnErrorMessage)
            }
        })

        if (dosesErr.some(row => row.some(el => !!el))) {
            errMessages = addElement(errMessages, doseFormatErrorMessage)
        }
        let therapyMedications: TherapyMedicationDto[] = []
        if (errMessages.length === 0) {
            // filter by valid intake
            const numberMultipliers = doses.map(row => row.map(el => toNumber(el?.toString()) as number)) // safe cast since it was checked previously
            therapyMedications = getTimeDoseObj(times, meds as MedicationSearchEntry[], numberMultipliers, props.unit, props.ingredients)
        }

        props.onValidate?.(therapyMedications, errMessages)
    }

    const unit = props.unit ? userStore.getI18nText(ConfigTypes.UNIT_MEASURE, props.unit) : '-'

    const isStrict = useMemo(() => {
        return hasAnyValidMedicationDto(meds)
    }, [meds])

    const unitToFilter = useMemo(() => {
        const medsFiltered = [...meds].filter(med => typeof med !== 'string');
        if (medsFiltered.length > 0) {
            const firstMedication = medsFiltered[0] as MedicationSearchEntry;
            return firstMedication.originallyHadUnit ? firstMedication.unit : props.unit
        }
        return props.unit
    }, [meds, props.unit])

    return (
        <div>
            <div style={sharedStyles.inline}>
                <div style={styles.column1}>
                    <EmptyDiv style={styles.columnHeader} />
                    <EmptyDiv inputHeight={true} />
                    {meds.map((med, index) => (
                        <div key={`x${index}${med}`} style={{...styles.center, marginTop: elemMargin / 2}}>
                            <CloseOutlined onClick={() => deleteMedicine(index)} />
                        </div>
                    ))}
                </div>

                <div style={styles.column2}>
                    <div style={styles.columnHeader}>{i18n.t('editMed.medicationLabel')}</div>
                    <EmptyDiv inputHeight={true} />
                    {generateNumberArray(meds.length).map(index => (
                        <div key={`${index}${meds[index]}`} style={{marginTop: elemMargin / 2}}>
                            <LiveSearch
                                searchType={SearchTypes.product}
                                placeholder={i18n.t('editMed.medicationPlaceholder')}
                                onValueChanged={onMedicineChange(index)}
                                searchOptions={{filter: props.ingredients?.map(i => i.id) || [], strict: isStrict, unit: unitToFilter}}
                                defaultValue={typeof meds[index] === 'string' ? undefined : meds[index]}
                                hideErrorField={true}
                                searchThreshold={4}
                                skipInitialize={true}
                            />
                        </div>
                    ))}
                </div>

                <div style={styles.column3}>
                    <div style={styles.columnHeader}>{i18n.t('editMed.dose')}</div>
                    <EmptyDiv inputHeight={true} />
                    {meds.map((med, index) => {
                        const {id, val} =
                            typeof med === 'string'
                                ? {id: `empty${index}`, val: med}
                                : {
                                      id: med.id,
                                      val: (med.originallyHadDosage ? med.dosage?.toLocaleString(i18n.locale) : med.uiDosage) || ''
                                  }
                        return (
                            <div key={id} style={{marginTop: elemMargin / 2}}>
                                <Input
                                    value={val}
                                    onValueChanged={onMedicationDosageChange(index)}
                                    onBlur={e => onMedicationDosageBlur(index)(e.target.value)}
                                    min={0}
                                    disabled={(typeof med !== 'string' && !!med.originallyHadDosage)} // disabled if medication is selected and has unit
                                    hideErrorField={true}
                                    skipInitialize={true}
                                    type={'number'}
                                />
                            </div>
                        )
                    })}
                </div>

                <div style={styles.column4}>
                    <div style={styles.columnHeader}>{i18n.t('editMed.unit')}</div>
                    <EmptyDiv inputHeight={true} />
                    {meds.map((med, index) => (
                        <div key={`unit${index}${med}`} style={{...styles.center, marginTop: elemMargin / 2}}>
                            {unit}
                        </div>
                    ))}
                </div>

                {times.map((time, timeIdx) => (
                    <div key={`header${timeIdx}`} style={styles.column5}>
                        <div style={{...sharedStyles.inlineContainer, ...styles.columnHeader}}>
                            <span>{i18n.t('editMed.intakeTime', {index: timeIdx + 1})}</span>
                            <CloseOutlined onClick={() => deleteIntakeTime(timeIdx)} style={{marginRight: elemMargin}} />
                        </div>
                        <div>
                            <Input
                                key={`val${timeIdx}`}
                                value={times[timeIdx]}
                                placeholder={Format.SimpleTime}
                                onValueChanged={onTimeValueChange(timeIdx)}
                                hideErrorField={true}
                            />
                        </div>
                        {meds.map((med, medIdx) => (
                            <div key={`${medIdx}${timeIdx}`} style={{marginTop: elemMargin / 2}}>
                                <Input
                                    value={toLocaleStringCustom(doses[medIdx][timeIdx], i18n.locale) || doses[medIdx][timeIdx]}
                                    placeholder={'0'}
                                    onValueChanged={onDosageChange(medIdx, timeIdx)}
                                    onBlur={() => onDosageBlur(medIdx, timeIdx)}
                                    error={dosesErr[medIdx][timeIdx]}
                                    hideErrorField={true}
                                    type={'number'}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div style={sharedStyles.inlineContainer}>
                <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={addMedicine}>
                    {i18n.t('button.addDose')}
                </Button>
                <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={addIntakeTime}>
                    {i18n.t('button.addIntakeTime')}
                </Button>
            </div>

            {/* Dosage comparison display */}
            <div style={{...sharedStyles.inline, background: Color.lightPurple, padding: 10}}>
                <div style={{...sharedStyles.leftColumn, alignItems: 'center'}}>
                    <span style={{fontSize: 17}}>{i18n.t('editMed.dailyDose')}</span>
                    <span style={{fontSize: 20, fontWeight: 'bold'}}>
                        {prepareDosageForDisplay(props.dailyDose) || 0} {unit}
                    </span>
                </div>
                <div style={{...sharedStyles.rightColumn, alignItems: 'center'}}>
                    <span style={{fontSize: 17}}>{i18n.t('editMed.totalDose')}</span>
                    <span
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold'
                        }}>
                        {totalDosage >= 0 ? `${prepareDosageForDisplay(totalDosage)} ${unit}` : '-'}
                    </span>
                </div>
            </div>
        </div>
    )
})

type TimeDoseTableProps = {
    therapyMedicationList?: TherapyMedicationDto[]
    unit?: string
    dailyDose?: number
    ingredients?: IngredientSearchEntry[]
    setMedicationChanged?: (selectedMedication: MedicationSearchEntry | null) => void
    triggerValidate: boolean
    onValidate?: (therapyMedications: TherapyMedicationDto[], errorMessages: string[]) => void
    onChangeUnit?: (unit: string | null) => void
}

export default TimeDoseTable
