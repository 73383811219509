import {LocalStyleSheet} from '../../../utils/types'
import {sharedStyles} from '../../../style/shared_styles'
import {Color} from '../../../style/colors'

export const styles: LocalStyleSheet = {
    messageContainer: {
        ...sharedStyles.container,
        background: Color.yellow,
        display: 'block'
    },

    messageTitle: {
        fontSize: 18,
        marginBottom: 0,
        fontWeight: 'bold'
    }
}

export default styles
