import React, {CSSProperties} from 'react'
import {Color} from '../../style/colors'

/**
 * The component used for rendering the title of the modals or of the major page sections.
 * The displayed title value will have a custom style.
 */
const TitleText: React.FC<TitleTextProps> = (props: TitleTextProps) => {
    return (
        <span
            style={{
                color: props.color || Color.active,
                fontSize: props.size || 18,
                fontWeight: 'bold',
                ...props.style
            }}>
            {props.text.toUpperCase()}
        </span>
    )
}

type TitleTextProps = {
    text: string
    size?: number
    color?: string
    style?: CSSProperties
}

export default TitleText
