import React, {useEffect, useState} from 'react'
import {Header} from 'antd/es/layout/layout'
import {AutoComplete, Image, Menu, Spin} from 'antd'
import {CaretDownFilled, HomeFilled, SearchOutlined} from '@ant-design/icons'
import {Routes} from '../../utils/routes'
import SubMenu from 'antd/es/menu/SubMenu'
import {i18n} from '../../i18n'
import {observer} from 'mobx-react'
import {useServices, useStores} from '../../store'
import {styles} from './styles/AppHeader.style'
import {OptionItem, PatientSearchEntry} from '../../utils/types'
import {SearchTypes} from '../../utils/enums'
import {useDebounce} from '../../utils/hooks/useDebounce'
import {Logo} from '../../assets'
import { useNavigate } from 'react-router-dom'

const searchThreshold = 2
const debounceDelay = 400

/**
 * The custom component used for rendering the application header.
 */
const AppHeader: React.FC = observer(() => {
    const [searchString, setSearchString] = useState<string>('')
    const [patientOptions, setPatientOptions] = useState<OptionItem[]>([])

    const {userStore} = useStores()
    const {searchService} = useServices()

    const navigate = useNavigate()

    const triggerSearch = async (searchStr: string) => {
        setSearchString(searchStr)
    }

    const getDataServer = async (searchValue: string) => {
        const results = (await searchService.genericSearch(searchValue, SearchTypes.patient)) as PatientSearchEntry[]
        const newOptions: OptionItem[] = results.map(entry => ({
            key: entry.id,
            value: entry.name,
            label: entry.name
        }))
        setPatientOptions(newOptions)
    }

    const debouncedSearch = useDebounce(getDataServer, debounceDelay)

    useEffect(() => {
        if (searchString.length >= searchThreshold) {
            debouncedSearch(searchString)
        }
    }, [searchString])

    const triggerSelect = (val: string, option: OptionItem) => {
        setSearchString('')
        console.log("Option: ", option)
        navigate(`${Routes.PATIENT}/${option.key}`)
    }

    const placeholder = (
        <>
            <SearchOutlined style={styles.navSearchIcon} />
            {i18n.t('header.search.placeholder')}
        </>
    )
    const noOptionsFound = searchString?.length > searchThreshold && <span>{i18n.t('header.search.noOptions')}</span>
    return (
        <Header style={styles.header}>
            <div style={styles.logo}>
                <Image src={Logo} preview={false} style={{height: 25}} />
            </div>
            <Menu theme='dark' mode='horizontal' style={styles.menu}>
                <Menu.Item key={Routes.HOME} onClick={() => navigate(Routes.HOME)}>
                    <HomeFilled style={{fontSize: 20}} />
                </Menu.Item>
                <Menu.Item key={'tinodoc'} style={styles.marginRightAuto}>
                    <a onClick={() => window.location.replace(userStore.getTinoDocUrl!)}>
                        {i18n.t('header.companion.link')}
                    </a>
                </Menu.Item>
                <Menu.Item key={'search'} style={styles.menuItemNavSearch}>
                    <div style={styles.navSearchWrapper}>
                        {/*hack to prevent autofill in Chrome when open ChangePass modal*/}
                        <form style={{width: '100%'}} autoComplete={'nope'}>
                            <AutoComplete
                                style={styles.navSearch}
                                value={searchString}
                                notFoundContent={noOptionsFound}
                                placeholder={placeholder}
                                onChange={triggerSearch}
                                onSelect={triggerSelect}
                                options={patientOptions}
                                allowClear={true}
                            />
                        </form>
                    </div>
                </Menu.Item>

                <SubMenu key={'UserSubMenu'} title={
                        <span>
                            {userStore.userProfile ? userStore.getUserLoggedName : <Spin />} <CaretDownFilled />
                        </span>
                    }>
                    <Menu.Item key={'submenu-home'} onClick={() => navigate(Routes.HOME)}>
                        {i18n.t('header.pages.home')}
                    </Menu.Item>
                    <Menu.Item key={Routes.MY_PROFILE} onClick={() => navigate(Routes.MY_PROFILE)}>
                        {i18n.t('header.pages.profile')}
                    </Menu.Item>
                    <Menu.Item key={Routes.ABOUT} onClick={() => navigate(Routes.ABOUT)}>
                        {i18n.t('header.pages.aboutUs')}
                    </Menu.Item>
                    <Menu.Item key={'logout'} onClick={() => userStore.logout()}>{i18n.t('header.pages.logout')}</Menu.Item>
                </SubMenu>
            </Menu>
        </Header>
    )
})

export default AppHeader
